import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'breite12 | Gemeinschaftsbüro in Reudnitz, Leipzig', // e.g: 'Name | Developer'
  lang: 'de', // e.g: en, es, fr, jp
  description:
    'Hi! Wir sind Annika & Magnus und vereinen Webdesign und -Entwicklung. Wir wollen uns unserem kleinen Büro einen Raum für Kreativität und Zusammenkunft schaffen.', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hi, wir sind ',
  name: 'breite12.',
  subtitle: 'Ein kreatives Digitalbüro im Leipziger Osten.',
  cta: 'Lust uns kennenzulernen?',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne:
    'Breite 12, ganz schön fiffig abgeleitet aus unserer Adresse - wir sind ein Gemeinschaftsbüro, zu finden in der Breiten Str. 12 in 04317 Leipzig. ',
  paragraphTwo:
    'Gestartet haben wir unser Projekt im November 2020. Man würde meinen, in Zeiten einer globalen Pandemie nicht der smarteste Move - aber dann doch gerade Recht!',
  paragraphThree:
    'Wir wollten einen Ort, wo wir kreativ sein können, sei es on- oder offlline, gemeinsam tüfteln und gute Sachen kreieren. Lernen und von den Stärken der anderen profitieren, das ist die Mission.',
  // resume: 'https://www.resumemaker.online/es.php', // if no resume, the button will not show up
};

export const projectsData = [
  {
    id: nanoid(),
    img: 'kmag.png',
    title: 'k-mag industries | Magnus',
    info:
      'Magnus ist bei uns für alles 3D Modelling, Augmented Reality, IT-Projektmanagement & Performance Marketing zuständig.',
    info2:
      'Allrounder mit guten Kontakten, der als digital native sicherlich bei deiner Mission weiterhelfen kann.',
    // url: 'https://eisberg.io',
    instagram: 'https://www.instagram.com/k_mag94/',
    linkedin: 'https://de.linkedin.com/in/magnus-krause',
  },
  {
    id: nanoid(),
    img: 'wischn.png',
    title: 'wischn | Annika',
    info:
      'Annika ist bei uns für alles Design-bezogene zuständig. Wenn du am Anfang deiner Markenreise stehst, bekommst du hier Hilfe vom Corporate Design bis zur performant programmierten Webseite. Wenn du schon weiter auf deiner Reise bist, unterstützt sie gerne bei Web-, Print- und Grafikdesignprojekten.',
    info2: 'Frontenddesign & -entwicklung, Fotografie, Illustrationen, Clips & Contentcreation',
    url: 'https://www.wischn.com',
    instagram: 'https://www.instagram.com/wischn_/',
    linkedin: 'https://www.linkedin.com/in/annika-wischnewsky-78a7a3132/',
  },
  {
    id: nanoid(),
    img: 'umfeld.pngg',
    title: 'unser Umfeld',
    info:
      'Wir sind ein Gemeinschaftsbüro. Neben Magnus & Annika trifft man hier immer mal unterschiedliche Freiberufler an. Wenn wir dir also nicht direkt weiterhelfen können, sei dir sicher, wir kennen da jemanden. Wir haben einen Besprechungsraum, der multifunktional genutzt wird, mal als Workshoport, mal als Fotostudio. Oder für jeglich sonstiges Kreativprojekt. Du bist auf der Suche nach einer Fläche, um Dinge zu verwirklichen? Dann meld dich gerne bei uns, wir vermieten den Raum auf Stunden- oder Tagesbasis.',
    info2:
      'Wenn das alles nicht bereits begeistert, können wir außerdem mit einer Bar, Küche und Sofa glänzen, komm vorbei und lern uns kennen.',
    // url: 'www.wischn.com',
    // repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Lust mit uns zu arbeiten?',
  btn: 'Meld dich doch!',
  email: 'hi@breite12.work',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'instagram',
      url: 'https://www.instagram.com/breite12/',
    },
    // {
    //   id: nanoid(),
    //   name: 'codepen',
    //   url: '',
    // },
    // {
    //   id: nanoid(),
    //   name: 'linkedin',
    //   url: '',
    // },
    // {
    //   id: nanoid(),
    //   name: 'github',
    //   url: '',
    // },
  ],
};
